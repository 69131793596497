<template>
  <div class="index" v-padding:top="46">
    <van-nav-bar title="首页" fixed>
      <template #left v-if="userType == 1">
        <van-icon
          name="iconfont iconfont iconqiehuan"
          size="24"
          color="#C2D3DF"
          @click="changeUser"
        />
      </template>
    </van-nav-bar>
    <panel title="未完结事件统计">
      <div class="statistics">
        <div
          class="statistics-item"
          v-for="(item, index) in eventStatistics"
          :key="index"
          :style="{ background: item.color }"
        >
          <div class="statistics-item-value">{{ item.value }}</div>
          <div class="statistics-item-label">{{ item.label }}</div>
        </div>
      </div>
    </panel>
    <panel title="企业统计">
      <div slot="extra">
        <p class="panel-extra" v-for="(item, index) in company" :key="index">
          企业总数：
          <span class="panel-extra-main"> {{ item.sum }} </span>家
        </p>
      </div>
      <div class="block-div">
        <div class="blocks" v-for="(item, index) in enterStat" :key="index">
          <p class="num" :style="{ color: item.enterColor }">
            {{ item.enterNum }}
          </p>
          <p class="title">{{ item.enterTitle }}</p>
        </div>
      </div>
    </panel>
    <!-- <panel title="当日运维统计">
      <div class="block-count">
        <div class="block-stat" v-for="(item, index) in opsStat" :key="index">
          <p class="num">{{ item.opsNum }}</p>
          <p class="title">{{ item.opsTitle }}</p>
          <p class="iconfont opration-icon" :class="item.iconName"></p>
        </div>
      </div>
    </panel> -->
  </div>
</template>

<script>
import panel from "@/components/common/panel.vue";
// 公共面板
export default {
  components: { panel },
  data() {
    return {
      beginTime: this.$moment().format("YYYY-MM-DD") + " 00:00:00", // 开始时间
      endTime: this.$moment().format("YYYY-MM-DD") + " 23:59:59", // 结束时间
      eventStatistics: [
        {
          color: "#E05F38",
          label: "一级事件",
          value: 0,
        },
        {
          color: "#4EC294",
          label: "二级事件",
          value: 0,
        },
        {
          color: "#378AED",
          label: "三级事件",
          value: 0,
        },
        // {
        //   color: "#A6A6A6",
        //   label: "巡检事件",
        //   value: 0
        // }
      ],
      opsStat: [
        { opsNum: 0, opsTitle: "日常巡检", iconName: "iconxunjian1" },
        { opsNum: 0, opsTitle: "标液核查", iconName: "iconchaxun" },
        { opsNum: 0, opsTitle: "校验", iconName: "iconxiaoyan" },
        { opsNum: 0, opsTitle: "校准", iconName: "iconxiaozhun1" },
        { opsNum: 0, opsTitle: "比对", iconName: "iconbidui1" },
        { opsNum: 0, opsTitle: "试剂更换", iconName: "icongenghuan1" },
        { opsNum: 0, opsTitle: "标液更换", iconName: "icongenghuan1" },
        { opsNum: 0, opsTitle: "故障维修", iconName: "iconweixiubanshou" },
        { opsNum: 0, opsTitle: "废液收集", iconName: "iconshouji" },
      ],
      enterStat: [
        { enterNum: 0, enterTitle: "站房", enterColor: "#528FD9" },
        { enterNum: 0, enterTitle: "超标", enterColor: "#E05F38" },
        { enterNum: 0, enterTitle: "异常", enterColor: "#E58D0F" },
        { enterNum: 0, enterTitle: "离线", enterColor: "#545252" },
      ],
      company: [{ sum: 0 }],
    };
  },
  mounted() {
    this.getEventStatistics();
    this.getOpsStatistics();
    this.getEnterStatistics();
  },
  computed: {
    userType() {
      return localStorage.getItem("userType");
    },
  },
  methods: {
    // 获取事件统计
    getEventStatistics() {
      this.$api.index.getEventStatistics().then((res) => {
        if (res.data.state == 0) {
          this.eventStatistics[0].value = res.data.data.enevt1;
          this.eventStatistics[1].value = res.data.data.enevt2;
          this.eventStatistics[2].value = res.data.data.enevt3;
          // this.eventStatistics[3].value = res.data.data.checkingStatistics;
        }
      });
    },
    // 获取当日运维统计
    getOpsStatistics() {
      const data = {
        beginTime: this.beginTime,
        endTime: this.endTime,
      };
      this.$api.index.getOpsStatistics(data).then((res) => {
        if (res.data.state == 0) {
          this.opsStat[0].opsNum = res.data.data.project1
            ? res.data.data.project1
            : 0;
          this.opsStat[1].opsNum = res.data.data.project2
            ? res.data.data.project2
            : 0;
          this.opsStat[2].opsNum = res.data.data.project3
            ? res.data.data.project3
            : 0;
          this.opsStat[3].opsNum = res.data.data.project4
            ? res.data.data.project4
            : 0;
          this.opsStat[4].opsNum = res.data.data.project5
            ? res.data.data.project5
            : 0;
          this.opsStat[5].opsNum = res.data.data.project6
            ? res.data.data.project6
            : 0;
          this.opsStat[6].opsNum = res.data.data.project7
            ? res.data.data.project7
            : 0;
          this.opsStat[7].opsNum = res.data.data.project8
            ? res.data.data.project8
            : 0;
          this.opsStat[8].opsNum = res.data.data.project9
            ? res.data.data.project9
            : 0;
          // this.opsStat[9].opsNum = res.data.data.project10
          //   ? res.data.data.project10
          //   : 0;
          // this.opsStat[10].opsNum = res.data.data.project11
          //   ? res.data.data.project11
          //   : 0;
          // this.opsStat[11].opsNum = res.data.data.project12
          //   ? res.data.data.project12
          //   : 0;
        }
      });
    },
    // 获取企业统计
    getEnterStatistics() {
      this.$api.index.getEnterStatistics().then((res) => {
        if (res.data.state == 0) {
          this.enterStat[0].enterNum = res.data.data.stationNumber
            ? res.data.data.stationNumber
            : 0;
          this.enterStat[1].enterNum = res.data.data.overproof
            ? res.data.data.overproof
            : 0;
          this.enterStat[2].enterNum = res.data.data.anomaly
            ? res.data.data.anomaly
            : 0;
          this.enterStat[3].enterNum = res.data.data.offLine
            ? res.data.data.offLine
            : 0;
          this.company[0].sum = res.data.data.enterpriseNumber
            ? res.data.data.enterpriseNumber
            : 0;
        }
      });
    },
  },
};
</script>

<style></style>
