<template>
  <div class="panel">
    <div class="header" v-if="!hiddenHeader">
      <div class="title">{{ title }}</div>
      <div class="extra">
        <slot name="extra"></slot>
      </div>
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    hiddenHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.panel {
  background: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 15px;
  box-sizing: border-box;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .title {
      font-size: 17px;
      line-height: 24px;
      color: #000;
      border-bottom: 2px solid rgba(55, 138, 237, 0.5);
      position: relative;
      box-sizing: border-box;
      &::after {
        content: "";
        display: inline-block;
        width: 50%;
        position: absolute;
        bottom: -2px;
        left: 0px;
        height: 2px;
        background: #378aed;
      }
    }
    .extra {
      line-height: 24px;
      .panel-extra {
        font-size: 14px;
        color: #4c4c4c;
        .panel-extra-main {
          font-size: 18px;
          color: #44be8e;
          margin-left: 10px;
          margin-right: 14px;
        }
      }
    }
  }
}
</style>
